<template>
    <div class="flex-column " >
        <sticky :className="'sub-navbar'">
            <div class="filter-container borderBotton ">
                <permission-btn moduleName='wmsGoodsBatchMsts' size="mini" v-on:btn-event="onBtnClicked"></permission-btn>
            </div>
        </sticky>
        <div class="app-container flex-item bgcolor ">
            <el-timeline class="centers">
                <el-timeline-item  v-for="(activity, index) in activities"  :key="index" :icon="activity.icon"  :type="activity.type"  :size="activity.size" :timestamp="activity.day">
                <el-card>
                    <h3>版本号：{{activity.version}}</h3>
                    <div style="display:flex">
                    <h4 style="width:80px">更新内容：</h4>
                    <h4>
                        <div v-for="(item,index) in activity.remark " :key="index">
                            {{index+1}}、{{item}}
                        </div>
                    </h4>
                    </div>
                </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>
        <el-dialog   v-el-drag-dialog  class="dialog-mini" width="40%" title="更新PDA版本信息" v-loading="$store.state.listLoading" :visible.sync="pdaversion"  @close="cleardata">
            <el-form :rules="rules" ref="dataForm" :model="versionData" label-position="right" label-width="60px" style="padding:20px;"> 
                <el-form-item size="small" :label="'版本号'" prop="version"  maxlength="25">
                    <el-input v-model="versionData.version"   placeholder="请输入版本号"></el-input>
                </el-form-item>
                <el-form-item size="small" :label="'更新内容'" prop="remark" style="height: auto;">
                    <div v-for="(item,index) in versionData.remark" :key="index" >
                         <el-input v-model="versionData.remark[index]"   type="input"  placeholder="请输入更新内容">
                             <template slot="prepend">{{index+1}}</template>
                             <el-button v-if="versionData.remark.length==index+1?true:false" slot="append" icon="el-icon-plus" @click="addRemark"></el-button>
                         </el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div style="text-align:right;" slot="footer">
                <el-button size="small" type="danger" @click="pdaversion = false">取消</el-button>
                <el-button size="small" type="primary" @click="createpda">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import * as pdarelevant from '@/api/pdarelevant/pdarelevant'
    import waves from '@/directive/waves' // 水波纹指令
    import Sticky from '@/components/Sticky'
    import permissionBtn from '@/components/PermissionBtn'
    import elDragDialog from '@/directive/el-dragDialog'
    import extend from "@/extensions/delRows.js"
    export default {
        name: 'wmsGoodsBatchMst',
        components: { Sticky, permissionBtn, },
        mixins: [extend],
        directives: {
            waves,
            elDragDialog
        },
        data() {
            return {
                pdaversion:false,
                activities: [{content: '版本号', }],
                listQuery: { // 查询条件
                    page: 1,
                    limit: this.$store.state.pageSizes,
                    key: undefined,
                    appId: undefined
                },
                versionData:{
                    version:"",
                    remark:""
                },
                rules: {
                    version:[{  required:true,message:'版本号不能为空',trigger:'blur'  }],
                },
            }
        },
        filters: {
            
        },
        created() {
        },
        mounted() {
            this.getList()
        },
        activated() {
            
        },
        methods: {
            addRemark(){
                this.versionData.remark.push("")
            },
           
            onBtnClicked: function(domId) {
                console.log('you click:' + domId)
                switch (domId) {
                    case 'btnAdd':
                        this.handleCreate()
                        break
                    case 'btnEdit':
                        if (this.multipleSelection.length !== 1) {
                            this.$message({
                                message: '只能选中一个进行编辑',
                                type: 'error'
                            })
                            return
                        }
                        this.handleUpdate(this.multipleSelection[0])
                        break
                    case 'btnDel':
                        if (this.multipleSelection.length < 1) {
                            this.$message({
                                message: '至少删除一个',
                                type: 'error'
                            })
                            return
                        }
                        this.handleDelete(this.multipleSelection)
                        break
                    default: 
                        break
                }
            },
            handleFilter(){
                this.getList()
            },
            getList() {
                pdarelevant.versionNum().then(response => {
                    if(response.code == '200'){
                        this.activities = response.result
                        this.activities.forEach((item)=>{
                            if(item.enable){
                                item.size= 'large'
                                item.type= 'success'
                                item.icon= 'el-icon-time'
                            }else{
                                item.size= 'large'
                                item.type= 'success'
                                item.icon= 'el-icon-circle-check'
                            }
                            item.remark=item.remark.split(",")
                            item.day=item.createTime.substr(0,10)
                        })
                       
                    }else{
                        this.$message({
                            message: response.message,
                            type: 'error'
                        })
                        this.activities = []
                    }
                }).catch(()=>{
                    this.$message({
                        message: "添加失败",
                        type: 'error'
                    })
                    this.activities = []
                })
            },

            handleCreate() { // 弹出添加框
                let bigV=this.activities.length>0?this.activities[0].version.substr(0, this.activities[0].version.lastIndexOf(".")):''
                let smallV=parseInt(this.activities.length>0?this.activities[0].version.substr(this.activities[0].version.lastIndexOf(".")+1):0)+1
                if(smallV<=9){
                    smallV="0"+smallV
                }
                this.versionData.version=bigV+'.'+smallV
                this.versionData.remark=['','','']
                this.pdaversion=true
            },
            cleardata(){
                this.versionData.version=""
                this.versionData.remark=[]
            },
            createpda(){
                this.$refs['dataForm'].validate((valid) => {
					if (valid) {
                        let data={}
                        data.version=this.versionData.version
                        data.remark=this.versionData.remark.filter(function (s) {
                            return s && s.trim();
                            }).toString()
                        this.$store.commit("updataListLoading",true)
                        pdarelevant.addVersionNum(data).then((res)=>{
                            if(res.code=='200'){
                                this.versionData.version=""
                                this.versionData.remark=[]
                                this.$notify({
                                    title: '成功',
                                    message: res.message,
                                    type: 'success',
                                    duration: 2000
                                })
                                this.getList()
                                this.pdaversion=false
                            }else{
                                this.$notify({
                                    title: '失败',
                                    message: res.message,
                                    type: 'error',
                                    duration: 2000
                                })
                            }
                           this.$store.commit("updataListLoading",false)
                        }).catch(()=>{
                            this.$notify({
                                title: '失败',
                                message: '添加失败',
                                type: 'error',
                                duration: 2000
                            })
                           this.$store.commit("updataListLoading",false)
                        })
                    }
                })
            },
         
        }
    }
</script>
<style lang="scss" scoped  type="text/scss">
    .borderBotton{
        border-bottom:1px solid #eae6e6 ;
    }
    .bgcolor{
        background-color: #F8F8F8;
    }
    .centers{
        margin: 0 auto !important;
        width: 55% !important;
        background-color: #F8F8F8;
    }
    .top_search_csss{
        height: 30px;
        line-height: 30px;
        width: 280px !important;
        margin-right:10px;
    }
    .dialog-mini .el-select{
        width:100%;
    }
    .custom-theme  .longYpltable  ::v-deep .el-table__body-wrapper,.longYpltable  ::v-deep .el-table__body-wrapper{
        height: calc(100% - 36px) !important;
    }
    .special_form ::v-deep .el-form-item{
        width: 50%;
        float: left;
    }
    ::v-deep .el-timeline{ 
    .el-timeline-item:first-child{
        .el-timeline-item__node--success {
            transform: scale(2);
        }
        .el-timeline-item__timestamp {
                position: absolute;
            left: -120px;
            top: -6px;
            color: #333333;
            font-size: 20px;
        }
    }
        .el-timeline-item__timestamp {
        position: absolute;
        left: -90px;
        top: -6px;
        color: #333333;
        font-size: 16px;
    }
        .el-timeline {
        padding-left: 150px;
    }
    
        .el-timeline-item__tail{
            border-left: 1px solid green ;
    }
        
    }
    .borderBotton{
        border-bottom: none;
    }
</style>
